.fadeOut {
    opacity: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
    opacity: 10;
    width: auto;
    height: auto;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
.BorderStyle {
    border-style: groove;
    border-radius: 10;
    background-color: darkorange;
    /* font-weight: 800; */
    transform: scale(1.02);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2),
        -1px -1px 0px rgba(0, 0, 0, 0.2);
        opacity: 40;
}
.tableheader {
    background-color: #09183c;
    color: white;
}
@media only screen and (max-width: 500px) {
    table {
        width: 90%;
    }
    .focus {
        display: flex;
        flex-direction: column;
        max-width: 40%;
    }
}
.react-confirm-alert-button-group{
    /* display:flex */
    justify-content: center;
}
.react-confirm{
    font-size:20px;
    color:black;
    background-color:rgba(186, 183, 183, 0.918);
    padding-left: 8rem;
    padding-bottom: 0.5rem;
    margin-right: -1.8rem;
    margin-left: -1.8rem;
    margin-top: -2rem;
    padding-top: 0.5rem;
    font-family: 'Times New Roman', Times, serif;
}
table{
    width: auto;
    height: fit-content;
}
th{
    width: 20rem
}
.tabs .nav-link.active{
    color: black;
    background-color: white;
    border-bottom: 4px solid rgb(116, 234, 249);
}
.tabs-link {
    background-color: #f3f3f3;
    color: rgb(0, 0, 0);
}
.tabs .tabs-link {
    background-color: #f3f3f3;
}
.react-confirm{
    font-size:20px;
    color:black;
    background-color:rgba(186, 183, 183, 0.918);
    padding-left: 8rem;
    padding-bottom: 0.5rem;
    margin-right: -1.8rem;
    margin-left: -1.8rem;
    margin-top: -2rem;
    padding-top: 0.5rem;
    font-family: 'Times New Roman', Times, serif;
}
.title{
    background-color:rgba(186, 183, 183, 0.918);
    /* margin-left:8rem; */
    align-items: center;
    text-align: center;
    padding-left:1rem;
    padding-top:5px;
    padding-bottom:5px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
    font-size:23px
}
.scroll{
    overflow-x: auto;
    white-space: nowrap;
  }